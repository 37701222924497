import { projectCover } from "../../helper/Images/Projects"

const {
    hopeharbor,
    vizchat,
    crypto,
    netflix,
    whatsapp,
    warehouse,
    telegram,
    bloghub,
    aora,
    npmCover,
} = projectCover

export const data = [
    {
        image: npmCover,
        title: "String Utility TS",
        github: "https://github.com/gurrudev/string-utility.git",
        live: "https://www.npmjs.com/package/string-utility-ts",
        des: "String Utility TS is a lightweight, easy-to-use library for JavaScript and TypeScript that provides a collection of commonly used string manipulation functions...",
        tags: ["Node JS", "TypeScript", "npm"],
    },
    {
        image: aora,
        title: "Aora",
        github: "https://github.com/gurrudev/aora.git",
        live: "https://github.com/gurrudev/aora.git",
        des: "Aora is a video sharing platform where user can share short form videos created using React Native, Appwrite it is android app.",
        tags: [
            "React Native",
            "Node JS",
            "Tailwind CSS",
            "TypeScript",
            "Appwrite",
        ],
    },
    {
        image: hopeharbor,
        title: "Hope Harbor",
        github: "https://github.com/gurrudev/HopeHarbor.git",
        live: "https://hopeharbor.netlify.app",
        des: "The Hope Harbor is Crowd Funding platform where user can create a campaign and donate. This project is created using 'thirdweb' to deploy the smart contract...",
        tags: [
            "React JS",
            "Thirdweb",
            "Solidity",
            "Ethereum",
            "Node JS",
            "Tailwind CSS",
            "JavaScript",
        ],
    },
    {
        image: vizchat,
        title: "VizChat",
        github: "https://github.com/gurrudev/viz-chat",
        live: "https://vizchat.vercel.app",
        des: "VizChat is a cutting-edge video conferencing application designed to facilitate seamless and immersive virtual communication experiences. ...",
        tags: [
            "Next JS",
            "TypeScript",
            "Tailwind CSS",
            "Node JS",
            "Stream IO",
            "Clerk",
        ],
    },
    {
        image: crypto,
        title: "Crypto Tracker",
        github: "https://github.com/gurrudev/CryptoTracker.git",
        live: "https://cryptotrackerno1.netlify.app",
        des: "The Cryptocurrency Tracker project is a powerful and user-friendly web application designed to provide real-time...",
        tags: ["React JS", "Material UI", "CSS", "Node JS", "JavaScript"],
    },
    {
        image: bloghub,
        title: "BlogHub",
        github: "https://github.com/gurrudev/Blog-Hub",
        live: "https://bloghubsite.netlify.app",
        des: "Welcome to BlogHub, your go-to platform for unleashing your creativity through the art of blogging. Whether you're an experienced writer, a passionate storyteller...",
        tags: [
            "React JS",
            "Tailwind CSS",
            "Node JS",
            "Mongo DB",
            "Redux",
            "JavaScript",
        ],
    },
    {
        image: netflix,
        title: "Netflix Clone",
        github: "https://github.com/gurrudev/Netflix-Clone.git",
        live: "https://neflix-30554.web.app",
        des: "The Netflix Clone project is a captivating and meticulously crafted web application that brings the immersive...",
        tags: ["React JS", "Firebase", "CSS", "Node JS", "JavaScript"],
    },
    {
        image: whatsapp,
        title: "Whatsapp Bot",
        github: "https://github.com/gurrudev/whatsapp-bot-ecommerce.git",
        live: "",
        des: "Whatsapp-bot for e-commerce store using nodejs with no third party app's like 'Twillio' and 'Chatapi' ...",
        tags: ["Node JS", "JavaScript", "Meta"],
    },
    {
        image: warehouse,
        title: "Warehouse Inventory",
        github: "https://github.com/gurrudev/inventory-management-system.git",
        live: "http://warehousejetha.infinityfreeapp.com",
        des: "Inventory Management System with Php and MySql Invoice generation and easy to download invoice in PDF format Lightweight and easy ...",
        tags: ["PHP", "MySQL", "HTML", "CSS", "JavaScript"],
    },
    {
        image: telegram,
        title: "Telegram Bot",
        github: "https://github.com/gurrudev/TeleBot.git",
        live: "https://telegram-bot-kznr.onrender.com/",
        des: "This is my personal portfolio Bot, you can chat with...",
        tags: ["Node JS", "JavaScript"],
    },
]
