import { useEffect, useMemo, useState } from "react"
import "./projects.css"
import { data } from "./data"
import { motion } from "framer-motion"
import { CgArrowLongRightR } from "react-icons/cg"
import { Icons } from "../../helper/Images/Icons"
import { useTranslation } from "react-i18next"

const {
    reactIcon,
    htmlIcon,
    cssIcon,
    jsIcon,
    reduxIcon,
    nodejsIcon,
    mongodbIcon,
    solidityIcon,
    tailwindIcon,
    nextjsIcon,
    tsIcon,
    phpIcon,
    muiIcon,
    mysqlIcon,
    firebaseIcon,
    thirdwebIcon,
    metaIcon,
    appwriteIcon,
    npmIcon,
} = Icons

function Projects() {
    const MemoizedData = useMemo(() => data, [])
    const [works, setWorks] = useState([])
    const [filterWork, setFilterWork] = useState([])
    const [activeFilter, setActiveFilter] = useState("All")
    const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 })
    const [showProjects, setShowProjects] = useState(3)
    const [closeProject, setCloseProject] = useState(false)
    const { t } = useTranslation()
    const {
        heading1,
        heading2,
        showAllTxt,
        hideTxt,
        githubBtnTxt,
        liveDemoTxt,
    } = t("projects")

    const handleWorkFilter = (item) => {
        setActiveFilter(item)
        setAnimateCard([{ y: 100, opacity: 0 }])

        setTimeout(() => {
            setAnimateCard([{ y: 0, opacity: 1 }])

            if (item === "All") {
                setFilterWork(works)
            } else {
                setFilterWork(works.filter((work) => work.tags.includes(item)))
            }
        }, 500)
    }

    useEffect(() => {
        setWorks(MemoizedData)
        setFilterWork(MemoizedData)
    }, [MemoizedData])

    return (
        <section id="projects">
            <motion.h5
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -50 }}
                transition={{
                    duration: 1.25,
                    type: "spring",
                }}
            >
                {heading1}
            </motion.h5>
            <motion.h2
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: -50 }}
                transition={{
                    duration: 1.25,
                    type: "spring",
                    delay: 0.3,
                }}
            >
                {heading2}
            </motion.h2>
            <div>
                <div className="app__work-filter">
                    {[
                        "All",
                        "React JS",
                        "React Native",
                        "Node JS",
                        "Mongo DB",
                        "Redux",
                        "Next JS",
                        "TypeScript",
                        "Solidity",
                        "MySQL",
                        "PHP",
                        "JavaScript",
                    ].map((item, index) => (
                        <div
                            key={index}
                            onClick={() => handleWorkFilter(item)}
                            className={`app__work-filter-item app__flex ${
                                activeFilter === item ? "item-active" : ""
                            }`}
                        >
                            {item}
                        </div>
                    ))}
                    <motion.div
                        animate={animateCard}
                        transition={{ duration: 0.5, delayChildren: 0.5 }}
                        className="app__work-portfolio"
                    >
                        {filterWork
                            .slice(0, showProjects)
                            .map((work, index) => (
                                <div
                                    className="app__work-item app__flex"
                                    key={index}
                                >
                                    <div className="app__work-img app__flex">
                                        <img
                                            src={work.image}
                                            alt={work.title}
                                        />
                                    </div>
                                    <div className="project_techstacks">
                                        {work.tags.includes("PHP") ? (
                                            <img
                                                className="prj-icons"
                                                src={phpIcon}
                                                alt=""
                                            />
                                        ) : null}
                                        {work.tags.includes("Next JS") ? (
                                            <img
                                                className="prj-icons"
                                                src={nextjsIcon}
                                                alt=""
                                            />
                                        ) : null}
                                        {work.tags.includes("React JS") ? (
                                            <img src={reactIcon} alt="" />
                                        ) : null}
                                        {work.tags.includes("React Native") ? (
                                            <img src={reactIcon} alt="" />
                                        ) : null}
                                        {work.tags.includes("Node JS") ? (
                                            <img src={nodejsIcon} alt="" />
                                        ) : null}
                                        {work.tags.includes("Redux") ? (
                                            <img src={reduxIcon} alt="" />
                                        ) : null}
                                        {work.tags.includes("JavaScript") ? (
                                            <img src={jsIcon} alt="" />
                                        ) : null}
                                        {work.tags.includes("HTML") ? (
                                            <img src={htmlIcon} alt="" />
                                        ) : null}
                                        {work.tags.includes("CSS") ? (
                                            <img src={cssIcon} alt="" />
                                        ) : null}

                                        {work.tags.includes("Mongo DB") ? (
                                            <img src={mongodbIcon} alt="" />
                                        ) : null}
                                        {work.tags.includes("Solidity") ? (
                                            <img
                                                className="prj-icons"
                                                src={solidityIcon}
                                                alt=""
                                            />
                                        ) : null}
                                        {work.tags.includes("MySQL") ? (
                                            <img
                                                className="prj-icons"
                                                src={mysqlIcon}
                                                alt=""
                                            />
                                        ) : null}
                                        {work.tags.includes("Material UI") ? (
                                            <img
                                                className="prj-icons"
                                                src={muiIcon}
                                                alt=""
                                            />
                                        ) : null}
                                        {work.tags.includes("Tailwind CSS") ? (
                                            <img
                                                className="prj-icons"
                                                src={tailwindIcon}
                                                alt=""
                                            />
                                        ) : null}
                                        {work.tags.includes("TypeScript") ? (
                                            <img
                                                className="prj-icons"
                                                src={tsIcon}
                                                alt=""
                                            />
                                        ) : null}
                                        {work.tags.includes("Firebase") ? (
                                            <img
                                                className="prj-icons"
                                                src={firebaseIcon}
                                                alt=""
                                            />
                                        ) : null}
                                        {work.tags.includes("Meta") ? (
                                            <img
                                                className="prj-icons"
                                                src={metaIcon}
                                                alt=""
                                            />
                                        ) : null}
                                        {work.tags.includes("Thirdweb") ? (
                                            <img
                                                className="prj-icons"
                                                src={thirdwebIcon}
                                                alt=""
                                            />
                                        ) : null}
                                        {work.tags.includes("Appwrite") ? (
                                            <img
                                                className="prj-icons"
                                                src={appwriteIcon}
                                                alt=""
                                            />
                                        ) : null}
                                        {work.tags.includes("npm") ? (
                                            <img
                                                className="prj-icons"
                                                src={npmIcon}
                                                alt=""
                                            />
                                        ) : null}
                                    </div>
                                    <div className="app__work-content app__flex">
                                        <h3 className="bold-text">
                                            {work.title}
                                        </h3>
                                        <p className="p-text">{work.des}</p>
                                    </div>
                                    <div className="portfolio_item_CTA">
                                        <a
                                            href={work.github}
                                            className="btn"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {githubBtnTxt}
                                        </a>
                                        <a
                                            href={work.live}
                                            className="btn btn-primary"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {liveDemoTxt}
                                        </a>
                                    </div>
                                </div>
                            ))}
                    </motion.div>
                    {filterWork.length > 3 ? (
                        <div className="projectCount">
                            {!closeProject ? (
                                <h2
                                    className="showAll"
                                    onClick={() => {
                                        setShowProjects(filterWork.length)
                                        setCloseProject((prev) => !prev)
                                    }}
                                >
                                    {showAllTxt} <CgArrowLongRightR />
                                </h2>
                            ) : (
                                <h2
                                    className="showAll"
                                    onClick={() => {
                                        setShowProjects(3)
                                        setCloseProject((prev) => !prev)
                                    }}
                                >
                                    {hideTxt} <CgArrowLongRightR />
                                </h2>
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
        </section>
    )
}

export default Projects
