import hopeharbor from "../../../assets/HopeHarbor.png"
import vizchat from "../../../assets/vizchat.png"
import crypto from "../../../assets/cryptoTracker.png"
import netflix from "../../../assets/netflix3.png"
import whatsapp from "../../../assets/chatbot-for-whatsapp-01.jpg"
import warehouse from "../../../assets/warehouse-sc.png"
import telegram from "../../../assets/Telegram-Bot.png"
import bloghub from "../../../assets/bloghub.png"
import aora from "../../../assets/Aora.png"
import npmCover from "../../../assets/npmCover.png"

export const projectCover = {
    hopeharbor,
    vizchat,
    crypto,
    netflix,
    whatsapp,
    warehouse,
    telegram,
    bloghub,
    aora,
    npmCover,
}
